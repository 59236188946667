







































































import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import { TestimonialFilter } from 'client-website-ts-library/filters'
import ContentContainer from '@/components/Layout/ContentContainer.vue'
import SectionTitle from '@/components/Common/SectionTitle.vue'
import ContentRow from '@/components/Layout/ContentRow.vue'
import TestimonialCards from '@/components/Testimonials/TestimonialCards.vue'
import ItemCycler from '@/components/Cycler/ItemCycler.vue'
import { ItemCyclerEmbeddableSlide, ItemCyclerSlide } from '@/app_code/ItemCycler'
import { Testimonial } from 'client-website-ts-library/types'
import Form from '@/components/Forms/Form.vue'
import SideBySideSection from '@/components/SideBySideSection.vue'
import NavigationLink from '@/components/Common/NavigationLink.vue'

@Component({
  components: {
    TestimonialCards,
    ItemCycler,
    Form,
    ContentContainer,
    SectionTitle,
    ContentRow,
    SideBySideSection,
    NavigationLink,
  },
})
export default class HomeFinder extends Mixins(View) {
  private filter: TestimonialFilter = new TestimonialFilter();

  private cyclerItems: ItemCyclerSlide[] = [];

  handleGotTestimonials(testimonials: Testimonial[]) {
    this.cyclerItems = testimonials.filter((t) => t.Video !== null).map((t) => new ItemCyclerEmbeddableSlide(t.Video!))
  }
}
